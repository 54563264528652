
import { Vue, Component } from 'vue-property-decorator'
import store from '@/store/index'
import { TermAndConditionsInterface } from '@/store/types/Settings/TermsAndConditions'

@Component
export default class TermsAndConditionsComponent extends Vue {
  private acceptTermsValue = false
  private parsedData: any = {}

  private acceptTerms() {
    this.acceptTermsValue = !this.acceptTermsValue
  }

  private continueAction() {
    if (this.acceptTermsValue) {
      if (this.parsedData) {
        this.$mixpanel.track('Acepta T&C')
        store
          .dispatch('SettingsModule/acceptTermsAndConditions', {
            termAndConditionGuid: this.parsedData.guid,
          })
          .then(() => {
            this.$router.push('/startup/activity')
          })
      }
    }
  }
  private seeLaterAction() {
    this.$mixpanel.track('Ver T&C más tarde')
    this.$router.push('/startup/activity')
  }
  private getAbsoluteUrl(relativeUrl: string): string {
    if (relativeUrl.startsWith('http://') || relativeUrl.startsWith('https://')) {
      return relativeUrl
    } else {
      return 'http://' + relativeUrl
    }
  }
  mounted() {
    const storedData = localStorage.getItem('termNotAccepted')

    if (storedData) {
      const parsedData = JSON.parse(storedData)
      this.parsedData = JSON.parse(storedData)
    }
  }
}
